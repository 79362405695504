// If you want to use Phoenix channels, run `mix help phx.gen.channel`
// to get started and then uncomment the line below.
// import "./user_socket.js"

// You can include dependencies in two ways.
//
// The simplest option is to put them in assets/vendor and
// import them using relative paths:
//
//     import "../vendor/some-package.js"
//
// Alternatively, you can `npm install some-package --prefix assets` and import
// them using a path starting with the package name:
//
//     import "some-package"
//

// Include phoenix_html to handle method=PUT/DELETE in forms and buttons.
import "phoenix_html";
// Establish Phoenix Socket and LiveView configuration.
// Sentry
import * as Sentry from "@sentry/browser";
if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: "https://2b2ed843336825333f6401f47cdba26d@o73733.ingest.us.sentry.io/4506291593674752",
    environment: "prod",
  });
}
import "flowbite";
